import React from "react";
import { Link } from "gatsby";
import { ContentSection, HeaderSection } from "../docs/sections";
import { DocPageMeta, Header, InlineMessage } from "../docs";
import { Code } from "../docs/docs-code";
import { Paragraph, TextContainer, Ol, Ul } from "../../lib/src";

export const Meta: DocPageMeta = {
	category: "Developing",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Versioning Strategy"
				subTitle="Our plans for versioning Lib and how we'll deal with breaking changes."
			/>
			<ContentSection>
				<TextContainer article>
					<Header.H2>Background</Header.H2>
					<Paragraph>
						Since the Fancy Design System team is small and is serving multiple product teams with
						multiple different tech stacks, it’s important for us to make maintenance of Fancy as
						lean and efficient as possible and the usage for product teams as convenient as
						possible.
					</Paragraph>
					<Paragraph>
						Therefore we aim to use SemVer (Semantic Versioning) and only maintain a single version
						of FancyLib and are going for what we call a “Partial Migration” strategy. We go
						in-depth on this below, but it means we try to avoid a big bang release, and instead aim
						for partial migrations of new versions/updates to components.
					</Paragraph>
					<Paragraph>
						This also means that if issues occur in components, the most typical solution will be to
						upgrade to the latest version.
					</Paragraph>
					<Header.H2>SemVer</Header.H2>
					<Paragraph>
						We aim to use the <Link to="https://semver.org/">SemVer standard for versioning</Link>.
						The format of the version number is MAJOR.MINOR.PATCH (eg. 1.3.1). Increment the:
					</Paragraph>
					<Ol
						items={[
							<>
								MAJOR version when you make incompatible API changes (Breaking changes on existing
								components, deprecated components deleted, etc.)
							</>,
							<>
								MINOR version when you add functionality in a backward-compatible manner (New
								versions of existing components introduced with an alternative name, new components
								are introduced)
							</>,
							<>
								PATCH version when you make backward-compatible bug fixes (Eg. adding new
								non-required properties to existing components, fixing bugs that don’t require
								product teams to update code).
							</>,
						]}
					/>
					<Header.H2>How we plan to deal with breaking changes</Header.H2>
					<Paragraph>
						It’s inevitable that we’ll make breaking changes to Lib components or create completely
						new and different components that should be used instead of existing Lib components.
					</Paragraph>
					<Paragraph>We will handle these breaking changes by:</Paragraph>
					<Ol
						items={[
							<>
								In a minor version of FancyLib, introducing a new version of the component with the
								name <Code>ComponentnameNew</Code> and placing it next to the old one.
							</>,
							<>Marking the existing component as deprecated (aka. marked for deletion).</>,
							<>
								Communicate the change in #dev_fancy with a set date for the grace period. Depending
								on the change, it will also be communicated in other relevant channels.
							</>,
							<>
								Introducing a grace period to replace the deprecated component with the new one. The
								size of the grace period depends on the size and scope of the updated version to be
								replaced.
							</>,
							<>
								After the end of the grace period, a major version will be released where we delete
								the deprecated component and rename <Code>ComponentnameNew</Code> to{" "}
								<Code>Componentname</Code>. At this point, every reference to{" "}
								<Code>ComponentnameNew</Code> needs to be updated to <Code>Componentname</Code>. For
								a while, in PFG, this will be handled by the Fancy team.
							</>,
						]}
					/>
					<Paragraph>
						This way it’s possible to migrate while still on the old major version, without having
						to update major parts of your code in big-bang releases.
					</Paragraph>
					<InlineMessage variant="warning">
						<Paragraph>
							<strong>Note:</strong> It’s <em>important</em> going forward, that “Deprecated” labels
							are noted and respected. Failure to migrate away from deprecated components in a
							timely manner can lead to difficulties in upgrading versions down the line, which
							might be needed for bug fixes.
						</Paragraph>
					</InlineMessage>
					<Header.H2>Examples</Header.H2>
					<Header.H3>Scenario 1 - Design token update</Header.H3>
					<Paragraph>
						We want to rename a design token <Code>$color—heat—1</Code> to{" "}
						<Code>$color—scale—heat—1</Code>
					</Paragraph>
					<Ul
						items={[
							<>
								In a minor version of FancyLib, we introduce a new version called{" "}
								<Code>$color--scale--heat--1</Code>, next to the old one, and mark{" "}
								<Code>$color--heat--1</Code> as “Deprecated”.
							</>,
							<>
								Given the size of the update, this will be given a 30 day grace period, communicated
								in #dev_fancy
							</>,
							<>
								In a future major version <Code>$color--heat--1</Code> deleted.
							</>,
						]}
					/>
					<Header.H3>Scenario 2 - Component update</Header.H3>
					<Paragraph>We want to add a new required aria-label to Dropdowns.</Paragraph>
					<Ul
						items={[
							<>
								In a minor version of FancyLib, we introduce a new version called{" "}
								<Code>DropdownNew</Code>, next to the old one, and mark <Code>Dropdown</Code> as
								“Deprecated”.
							</>,
							<>
								Given the nature of this update, a developer might require input from either PO, UX
								writer, or Product Designer, therefore we will communicate the change to product
								teams / UX through relevant channels.
							</>,
							<>
								Given the size of this update, this will be given a 60 day grace period,
								communicated in #dev_fancy.
							</>,
							<>
								In a future major version Dropdown is deleted and <Code>DropdownNew</Code> is
								renamed to <Code>Dropdown</Code>.
							</>,
						]}
					/>
					<Header.H3>Scenario 3 - Pattern update</Header.H3>
					<Paragraph>
						We want to change how Table works, so that now table filters are part of the table
						component, meaning multiple components are affected.
					</Paragraph>
					<Ul
						items={[
							<>
								In a minor version of FancyLib, we introduce a new version called TableNew, next to
								the old one, and mark Table as “Deprecated”.
							</>,
							<>
								Given the potential impact on products or features, this will also be communicated
								at product sessions and the #product-owners slack channel and other relevant
								channels.
							</>,
							<>
								Given the size of this update, this will be given a 90 day grace period,
								communicated in #dev_fancy.
							</>,
							<>
								In a future major version Table is deleted and TableNew is renamed to Table.
								Documentation for Filters in TableToolbar is updated.
							</>,
						]}
					/>
				</TextContainer>
			</ContentSection>
		</>
	);
};
